const initialState = {
  saleByID: {}
}

const SalesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_SALE_BY_ID":
      // debugger
      return {
        saleByID: action.payload,
      }
  
    default:
      return state
  }
}

export default SalesReducer
