export const constants = {
  apiUrl: 'https://api.magnusmode.com/api/',
  // apiUrl: 'http://magnus-card-backend.herokuapp.com/public/api/',
  // apiUrl: 'http://172.16.1.66/magnus-card-backend/public/api/',
  // apiUrl: 'http://localhost:8000/api/',
  // apiUrl: 'https://magnus-api.synergo.ro/api/',
  progressBar: {
    barColors: {
      "0": "#000",
      "1.0": "#000"
    },
    shadowBlur: 5
  },
  SUPPORTED_FORMATS: [
    "image/jpg",
    "image/jpeg",
    // "image/gif",1493
    "image/png"
  ],

  DATA: [{ "id": 1, "email": 'admin@admin.com', "currentSignInAt": new Date().toJSON(), "lastSignInAt": new Date().toJSON(), "signInCount": 5 }],
  S3Config: {
    bucketName: 'magnus-card-new',
    // dirName: 'category',
    region: 'ca-central-1',
    accessKeyId: 'AKIATHXNXU4BVCZIJPNC',
    secretAccessKey: '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',
    s3Url: 'https://magnus-card-new.s3.ca-central-1.amazonaws.com',
  },
}
// 'https://cors-anywhere.herokuapp.com
