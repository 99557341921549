const initialState = {
  cities: []
}

const CustomerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CITIES":
      // // debugger
      return {
        cities: action.payload,
      }
  
    default:
      return state
  }
}

export default CustomerReducer
