import React from "react";
import * as Icon from "react-feather";
const navigationConfig = [
  // {
  //   id: "dashboard",
  //   title: "Dashboard",
  //   type: "item",
  //   icon: <Icon.Home size={20} />,
  //   badge: "warning",
  //   permissions: ["admin", "customer"],
  //   filterBase: "/dashboard",
  //   navLink: "/dashboard",
  // },
  {
    id: "AllAdmin",
    title: "Admins",
    type: "item",
    icon: <Icon.User size={20} />,
    badge: "warning",
    permissions: ["admin", "customer", "super admin"],
    navLink: "/pages/AllAdmin?perPage=10&page=1",
  },
  {
    id: "analytics",
    title: "Analytics",
    type: "item",
    icon: <Icon.Home size={20} />,
    badge: "warning",
    permissions: ["admin", "customer", "super admin", "editor"],
    filterBase: "/pages/Analytics",
    navLink: "/pages/Analytics",
  },
  {
    id: "AllLanguages",
    title: "Languages",
    type: "item",
    icon: <Icon.Globe size={20} />,
    badge: "warning",
    permissions: ["admin", "customer", "super admin", "editor"],
    navLink: "/pages/AllLanguages?perPage=10&page=1",
  },
  // {
  //   id: "AllLanguagesField",
  //   title: "Languages Field",
  //   type: "item",
  //   icon: <Icon.Aperture size={20} />,
  //   badge: "warning",
  //   permissions: ["admin", "customer"],
  //   navLink: "/pages/AllLanguagesField?perPage=10&page=1"
  // },
  {
    id: "AppLanguage",
    title: "App Language",
    type: "item",
    icon: <Icon.Database size={20} />,
    badge: "warning",
    permissions: ["admin", "customer", "super admin", "editor"],
    navLink: "/pages/AppLanguage",
  },
  {
    id: "AllCategory",
    title: "Categories",
    type: "item",
    icon: <Icon.AlignJustify size={20} />,
    badge: "warning",
    permissions: ["admin", "customer", "editor", "super admin"],
    navLink: "/pages/AllCategory?perPage=10&page=1&parent=1",
  },
  {
    id: "AllTag",
    title: "Tag",
    type: "item",
    icon: <Icon.Delete size={20} />,
    badge: "warning",
    permissions: ["admin", "customer", "editor", "super admin"],
    navLink: "/pages/AllTag?perPage=10&page=1",
  },
  // {
  //   id: "AllReference",
  //   title: "References",
  //   type: "item",
  //   icon: <Icon.BookOpen size={20} />,
  //   badge: "warning",
  //   permissions: ["admin", "customer"],
  //   navLink: "/pages/AllReference?perPage=10&page=1"
  // },
  {
    id: "AllCorporateBrands",
    title: "Corporate Brands",
    type: "item",
    icon: <Icon.Bold size={20} />,
    badge: "warning",
    permissions: ["admin", "customer", "super admin", "editor"],
    navLink: "/pages/AllCorporateBrand?perPage=10&page=1",
  },
  // my code
  {
    id: "AllBadges",
    title: "Badges",
    type: "collapse",
    icon: <Icon.Award size={20} />,
    badge: "warning",
    permissions: ["admin", "customer", "super admin", "editor"],
    // navLink: "/pages/AllBadges?perPage=10&page=1",
    children: [
      {
        id: "ManageBadges",
        title: "Manage Badges",
        type: "item",
        // icon: <Icon.Circle size={12} />,
        permissions: ["admin", "super admin", "editor"],
        navLink: "/pages/AllBadges",
      },
      {
        id: "ManageRule",
        title: "Manage Rules",
        type: "item",
        // icon: <Icon.Circle size={12} />,
        permissions: ["admin", "super admin", "editor"],
        navLink: "/pages/ManageRule",
      },
    ],
  },
  {
    id: "AllDecks",
    title: "Card Decks",
    type: "item",
    icon: <Icon.Link size={20} />,
    badge: "warning",
    permissions: ["admin", "customer", "editor", "super admin", "editor"],
    navLink: "/pages/AllDecks?page=1&perPage=10",
  },
  {
    id: "ApprovalDecks",
    title: "Card Decks Approvals",
    type: "item",
    icon: <Icon.Link size={20} />,
    badge: "warning",
    permissions: ["admin", "customer", "editor", "super admin", "editor"],
    navLink: "/pages/ApprovalDecks?page=1&perPage=10",
  },
  // {
  //   id: "AllCard",
  //   title: "Cards",
  //   type: "item",
  //   icon: <Icon.BookOpen size={20} />,
  //   badge: "warning",
  //   permissions: ["admin", "customer"],
  //   navLink: "/pages/AllCard?perPage=10&page=1"
  // },
  {
    id: "DecksHealth",
    title: "Card Decks Health",
    type: "item",
    icon: <Icon.Activity size={20} />,
    badge: "warning",
    permissions: ["admin", "customer", "super admin", "editor"],
    navLink: "/pages/DecksHealth",
  },
  {
    id: "AllUsers",
    title: "Users",
    type: "item",
    icon: <Icon.User size={20} />,
    badge: "warning",
    permissions: ["admin", "customer", "super admin", "editor"],
    navLink: "/pages/AllUsers?perPage=10&page=1",
  },
  {
    id: "Notifications",
    title: "Notifications",
    type: "item",
    icon: <Icon.Bell size={20} />,
    badge: "warning",
    permissions: ["admin", "customer", "super admin", "editor"],
    navLink: "/pages/Notifications",
  },
  {
    id: "TermsConditions",
    title: "Terms & Conditions",
    type: "item",
    icon: <Icon.FileText size={20} />,
    badge: "warning",
    permissions: ["admin", "customer", "editor", "super admin", "editor"],
    navLink: "/pages/TermsConditions",
  },
  {
    id: "AppSettings",
    title: "App Settings",
    type: "item",
    icon: <Icon.Settings size={20} />,
    badge: "warning",
    permissions: ["admin", "customer", "super admin", "editor"],
    navLink: "/pages/AppSettings",
  },

  // {
  //   id: "Users",
  //   title: "Users",
  //   type: "collapse",
  //   icon: <Icon.User size={20} />,
  //   badge: "warning",
  //   permissions: ["admin"],
  //   children: [
  //     {
  //       id: "AllUsers",
  //       title: "All Users",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin"],
  //       navLink: "/pages/AllUsers"
  //     },
  //     {
  //       id: "AddUsers",
  //       title: "Add Users",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin"],
  //       navLink: "/pages/AddUsers"
  //     },
  //   ]
  // },
  // {
  //   id: "customer",
  //   title: "Customer",
  //   type: "collapse",
  //   icon: <Icon.User size={20} />,
  //   badge: "warning",
  //   permissions: ["customer"],
  //   children: [
  //     {
  //       id: "AllCustomer",
  //       title: "All Customer",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/pages/AllCustomer"
  //     },
  //     {
  //       id: "AddCategory",
  //       title: "Add Customer",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/pages/AddCustomer"
  //     },
  //   ]
  // },
  // {
  //   id: "sales",
  //   title: "Sales",
  //   type: "collapse",
  //   icon: <Icon.DollarSign size={20} />,
  //   badge: "warning",
  //   permissions: ["customer"],
  //   children: [
  //     {
  //       id: "AllSales",
  //       title: "All Sales",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["customer"],
  //       navLink: "/pages/AllSales"
  //     },
  //     {
  //       id: "AddSales",
  //       title: "Add Sales",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["customer"],
  //       navLink: "/pages/AddSales"
  //     },
  //     {
  //       id: "SaleProfit",
  //       title: "Sales Profit",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["customer"],
  //       navLink: "/pages/SaleProfit"
  //     },
  // {
  //   id: "invoice",
  //   title: "Invoice",
  //   type: "item",
  //   icon: <Icon.File size={20} />,
  //   permissions: ["customer"],
  //   navLink: "/pages/invoice"
  // },
  //   ]
  // },
  // {
  //   id: "Purchase",
  //   title: "Purchase",
  //   type: "collapse",
  //   icon: <Icon.Framer size={20} />,
  //   badge: "warning",
  //   permissions: ["customer"],
  //   children: [
  // {
  //   id: "AllPurchase",
  //   title: "All Purchase",
  //   type: "item",
  //   icon: <Icon.Circle size={12} />,
  //   permissions: ["admin", "customer"],
  //   navLink: "/pages/AllPurchase"
  // },
  //     {
  //       id: "AddPurchase",
  //       title: "Add Purchase",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/pages/AddPurchase"
  //     },
  //   ]
  // },
  // {
  //   id: "product",
  //   title: "Product",
  //   type: "collapse",
  //   icon: <Icon.Layers size={20} />,
  //   badge: "warning",
  //   permissions: ["customer"],
  //   children: [
  //     {
  //       id: "AllProducts",
  //       title: "All Products",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["customer"],
  //       navLink: "/pages/AllProducts"
  //     },
  //     {
  //       id: "AddProduct",
  //       title: "Add Product",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["customer"],
  //       navLink: "/pages/AddProduct"
  //     },
  //   ]
  // },
  // {
  //   id: "SellReceiptVoucher",
  //   title: "Sell Receipt Voucher",
  //   type: "collapse",
  //   icon: <Icon.Book size={20} />,
  //   badge: "warning",
  //   permissions: ["customer"],
  //   children: [
  //     {
  //       id: "AllSellReceiptVoucher",
  //       title: "All Sell Receipt Voucher",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/pages/AllSellReceiptVoucher"
  //     },
  //     {
  //       id: "AddSellReceiptVoucher",
  //       title: "Add Sell Receipt Voucher",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/pages/AddSellReceiptVoucher"
  //     },
  //   ]
  // },
  // {
  //   id: "ViewLedger",
  //   title: "View Ledger",
  //   type: "collapse",
  //   icon: <Icon.BookOpen size={20} />,
  //   badge: "warning",
  //   permissions: ["customer"],
  //   children: [
  //     {
  //       id: "AllViewLedger",
  //       title: "All View Ledger",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/pages/AllViewLedger"
  //     },
  //   ]
  // },
  // {
  //   id: "ViewStock",
  //   title: "View Stock",
  //   type: "collapse",
  //   icon: <Icon.BookOpen size={20} />,
  //   badge: "warning",
  //   permissions: ["customer"],
  //   children: [
  //     {
  //       id: "AllViewStock",
  //       title: "All Stock",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/pages/AllViewStock"
  //     },
  //   ]
  // },
  // {
  //   id: "city",
  //   title: "City",
  //   type: "collapse",
  //   icon: <Icon.Home size={20} />,
  //   badge: "warning",
  //   children: [
  //     {
  //       id: "AllCity",
  //       title: "All City",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/pages/AllCity"
  //     },
  //     {
  //       id: "AddCity",
  //       title: "Add City",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/pages/AddCity"
  //     },
  //   ]
  // },
  // {
  //   id: "BusinessUnit",
  //   title: "Business Unit",
  //   type: "collapse",
  //   icon: <Icon.Users size={20} />,
  //   badge: "warning",
  //   permissions: ["admin"],
  //   children: [
  //     {
  //       id: "AllBusinessUnit",
  //       title: "All Business Unit",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin"],
  //       navLink: "/pages/AllBusinessUnit"
  //     },
  //     {
  //       id: "AddBusinessUnit",
  //       title: "Add Business Unit",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin"],
  //       navLink: "/pages/AddBusinessUnit"
  //     },
  //   ]
  // },
  // {
  //   id: "Branch",
  //   title: "Branch",
  //   type: "collapse",
  //   icon: <Icon.Bookmark size={20} />,
  //   badge: "warning",
  //   permissions: ["admin"],
  //   children: [
  //     {
  //       id: "AllBranch",
  //       title: "All Branch",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin"],
  //       navLink: "/pages/AllBranch"
  //     },
  //     {
  //       id: "AddBranch",
  //       title: "Add Branch",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin"],
  //       navLink: "/pages/AddBranch"
  //     },
  //   ]
  // },
  // {
  //   id: "Supplier",
  //   title: "Supplier",
  //   type: "collapse",
  //   icon: <Icon.User size={20} />,
  //   badge: "warning",
  //   permissions: ["customer"],
  //   children: [
  //     {
  //       id: "AllSupplier",
  //       title: "All Supplier",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["customer"],
  //       navLink: "/pages/AllSupplier"
  //     },
  //     {
  //       id: "AddSupplier",
  //       title: "Add Supplier",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["customer"],
  //       navLink: "/pages/AddSupplier"
  //     },
  //   ]
  // },
  // {
  //   id: "dashboard",
  //   title: "Dashboard",
  //   type: "collapse",
  //   icon: <Icon.Home size={20} />,
  //   badge: "warning",
  //   badgeText: "2",
  //   children: [
  //     {
  //       id: "analyticsDash",
  //       title: "Analytics",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/"
  //     },
  //     {
  //       id: "eCommerceDash",
  //       title: "eCommerce",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin"],
  //       navLink: "/dashboard"
  //     }
  //   ]
  // },
  // {
  //   type: "groupHeader",
  //   groupTitle: "APPS"
  // },
  // {
  //   id: "email",
  //   title: "Email",
  //   type: "item",
  //   icon: <Icon.Mail size={20} />,
  //   permissions: ["admin", "customer"],
  //   navLink: "/email/:filter",
  //   filterBase: "/email/inbox"
  // },
  // {
  //   id: "chat",
  //   title: "Chat",
  //   type: "item",
  //   icon: <Icon.MessageSquare size={20} />,
  //   permissions: ["admin", "customer"],
  //   navLink: "/chat"
  // },
  // {
  //   id: "todo",
  //   title: "Todo",
  //   type: "item",
  //   icon: <Icon.CheckSquare size={20} />,
  //   permissions: ["admin", "customer"],
  //   navLink: "/todo/:filter",
  //   filterBase: "/todo/all"
  // },
  // {
  //   id: "calendar",
  //   title: "Calendar",
  //   type: "item",
  //   icon: <Icon.Calendar size={20} />,
  //   permissions: ["admin", "customer"],
  //   navLink: "/calendar"
  // },
  // {
  //   id: "eCommerce",
  //   title: "Ecommerce",
  //   type: "collapse",
  //   icon: <Icon.ShoppingCart size={20} />,
  //   children: [
  //     {
  //       id: "shop",
  //       title: "Shop",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/ecommerce/shop"
  //     },
  //     {
  //       id: "detail",
  //       title: "Product Detail",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/ecommerce/product-detail"
  //     },
  //     {
  //       id: "wishList",
  //       title: "Wish List",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/ecommerce/wishlist"
  //     },
  //     {
  //       id: "checkout",
  //       title: "Checkout",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/ecommerce/checkout"
  //     }
  //   ]
  // },
  // {
  //   id: "users",
  //   title: "User",
  //   type: "collapse",
  //   icon: <Icon.User size={20} />,
  //   children: [
  //     {
  //       id: "list",
  //       title: "List",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/app/user/list"
  //     },
  //     {
  //       id: "view",
  //       title: "View",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/app/user/view"
  //     },
  //     {
  //       id: "edit",
  //       title: "Edit",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/app/user/edit/:id",
  //     }
  //   ]
  // },
  // {
  //   type: "groupHeader",
  //   groupTitle: "UI ELEMENTS"
  // },
  // {
  //   id: "dataList",
  //   title: "Data List",
  //   type: "collapse",
  //   icon: <Icon.List size={20} />,
  //   badge: "primary",
  //   badgeText: "new",
  //   children: [
  //     {
  //       id: "listView",
  //       title: "List View",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/data-list/list-view"
  //     },
  //     {
  //       id: "thumbView",
  //       title: "Thumb View",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/data-list/thumb-view"
  //     }
  //   ]
  // },
  // {
  //   id: "content",
  //   title: "Content",
  //   type: "collapse",
  //   icon: <Icon.Layout size={20} />,
  //   children: [
  //     {
  //       id: "gird",
  //       title: "Grid",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/ui-element/grid"
  //     },
  //     {
  //       id: "typography",
  //       title: "Typography",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/ui-element/typography"
  //     },
  //     {
  //       id: "textUitlities",
  //       title: "Text Utilities",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/ui-element/textutilities"
  //     },
  //     {
  //       id: "syntaxHighlighter",
  //       title: "Syntax Highlighter",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/ui-element/syntaxhighlighter"
  //     }
  //   ]
  // },
  // {
  //   id: "colors",
  //   title: "Colors",
  //   type: "item",
  //   icon: <Icon.Droplet size={20} />,
  //   permissions: ["admin", "customer"],
  //   navLink: "/colors/colors"
  // },
  // {
  //   id: "icons",
  //   title: "Icons",
  //   type: "item",
  //   icon: <Icon.Eye size={20} />,
  //   permissions: ["admin", "customer"],
  //   navLink: "/icons/reactfeather"
  // },
  // {
  //   id: "cards",
  //   title: "Cards",
  //   type: "collapse",
  //   icon: <Icon.CreditCard size={20} />,
  //   children: [
  //     {
  //       id: "basic",
  //       title: "Basic",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/cards/basic"
  //     },
  //     {
  //       id: "statistics",
  //       title: "Statistics",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/cards/statistics"
  //     },
  //     {
  //       id: "analytics",
  //       title: "Analytics",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/cards/analytics"
  //     },
  //     {
  //       id: "cardActions",
  //       title: "Card Actions",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/cards/action"
  //     }
  //   ]
  // },
  // {
  //   id: "components",
  //   title: "Components",
  //   type: "collapse",
  //   icon: <Icon.Briefcase size={20} />,
  //   children: [
  //     {
  //       id: "alerts",
  //       title: "Alerts",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/components/alerts"
  //     },
  //     {
  //       id: "buttons",
  //       title: "Buttons",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/components/buttons"
  //     },
  //     {
  //       id: "breadCrumbs",
  //       title: "Breadcrumbs",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/components/breadcrumbs"
  //     },
  //     {
  //       id: "carousel",
  //       title: "Carousel",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/components/carousel"
  //     },
  //     {
  //       id: "collapse",
  //       title: "Collapse",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/components/collapse"
  //     },
  //     {
  //       id: "dropDowns",
  //       title: "Dropdowns",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/components/dropdowns"
  //     },
  //     {
  //       id: "listGroup",
  //       title: "List Group",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/components/list-group"
  //     },
  //     {
  //       id: "modals",
  //       title: "Modals",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/components/modals"
  //     },
  //     {
  //       id: "pagination",
  //       title: "Pagination",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/components/pagination"
  //     },
  //     {
  //       id: "navsComponent",
  //       title: "Navs Component",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/components/nav-component"
  //     },
  //     {
  //       id: "navbar",
  //       title: "Navbar",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/components/navbar"
  //     },
  //     {
  //       id: "tabsComponent",
  //       title: "Tabs Component",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/components/tabs-component"
  //     },
  //     {
  //       id: "pillsComponent",
  //       title: "Pills Component",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/components/pills-component"
  //     },
  //     {
  //       id: "tooltips",
  //       title: "Tooltips",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/components/tooltips"
  //     },
  //     {
  //       id: "popovers",
  //       title: "Popovers",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/components/popovers"
  //     },
  //     {
  //       id: "badges",
  //       title: "Badges",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/components/badges"
  //     },
  //     {
  //       id: "pillBadges",
  //       title: "Pill Badges",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/components/pill-badges"
  //     },
  //     {
  //       id: "progress",
  //       title: "Progress",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/components/progress"
  //     },
  //     {
  //       id: "mediaObjects",
  //       title: "Media Objects",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/components/media-objects"
  //     },
  //     {
  //       id: "spinners",
  //       title: "Spinners",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/components/spinners"
  //     },
  //     {
  //       id: "toasts",
  //       title: "Toasts",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/components/toasts"
  //     }
  //   ]
  // },
  // {
  //   id: "extraComponents",
  //   title: "Extra Components",
  //   type: "collapse",
  //   icon: <Icon.Box size={20} />,
  //   children: [
  //     {
  //       id: "autoComplete",
  //       title: "Auto Complete",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/extra-components/auto-complete"
  //     },
  //     {
  //       id: "avatar",
  //       title: "Avatar",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/extra-components/avatar"
  //     },
  //     {
  //       id: "chips",
  //       title: "Chips",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/extra-components/chips"
  //     },
  //     {
  //       id: "divider",
  //       title: "Divider",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/extra-components/divider"
  //     }
  //   ]
  // },

  // {
  //   type: "groupHeader",
  //   groupTitle: "FORMS & TABLES"
  // },
  // {
  //   id: "formElements",
  //   title: "Form Elements",
  //   type: "collapse",
  //   icon: <Icon.Copy size={20} />,
  //   children: [
  //     {
  //       id: "select",
  //       title: "Select",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/forms/elements/select"
  //     },
  //     {
  //       id: "switch",
  //       title: "Switch",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/forms/elements/switch"
  //     },
  //     {
  //       id: "checkbox",
  //       title: "Checkbox",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/forms/elements/checkbox"
  //     },
  //     {
  //       id: "radio",
  //       title: "Radio",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/forms/elements/radio"
  //     },
  //     {
  //       id: "input",
  //       title: "Input",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/forms/elements/input"
  //     },
  //     {
  //       id: "inputGroup",
  //       title: "Input Group",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/forms/elements/input-group"
  //     },
  //     {
  //       id: "numberInput",
  //       title: "Number Input",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/forms/elements/number-input"
  //     },
  //     {
  //       id: "textarea",
  //       title: "Textarea",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/forms/elements/textarea"
  //     },
  //     {
  //       id: "date_&_timePicker",
  //       title: "Date & Time Picker",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/forms/elements/pickers"
  //     },
  //     {
  //       id: "inputMask",
  //       title: "Input Mask",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/forms/elements/input-mask"
  //     }
  //   ]
  // },
  // {
  //   id: "formLayouts",
  //   title: "Form Layouts",
  //   type: "item",
  //   icon: <Icon.Box size={20} />,
  //   permissions: ["admin", "customer"],
  //   navLink: "/forms/layout/form-layout"
  // },
  // {
  //   id: "wizard",
  //   title: "Form Wizard",
  //   type: "item",
  //   icon: <Icon.MoreHorizontal size={20} />,
  //   permissions: ["admin", "customer"],
  //   navLink: "/forms/wizard"
  // },
  // {
  //   id: "formik",
  //   title: "Formik",
  //   type: "item",
  //   icon: <Icon.CheckCircle size={20} />,
  //   permissions: ["admin", "customer"],
  //   navLink: "/forms/formik"
  // },
  // {
  //   id: "tables",
  //   title: "Tables",
  //   type: "collapse",
  //   icon: <Icon.Server size={20} />,
  //   children: [
  //     {
  //       id: "tablesReactstrap",
  //       title: "Reactstrap Tables",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/tables/reactstrap"
  //     },
  //     {
  //       id: "reactTables",
  //       title: "React Tables",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/tables/react-tables"
  //     },
  //     {
  //       id: "aggrid",
  //       title: "agGrid Table",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/tables/agGrid"
  //     },
  //     {
  //       id: "dataTable",
  //       title: "DataTables",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/tables/data-tables"
  //     }
  //   ]
  // },
  // {
  //   type: "groupHeader",
  //   groupTitle: "PAGES"
  // },
  // {
  //   id: "Slider",
  //   title: "Slider",
  //   type: "item",
  //   icon: <Icon.Settings size={20} />,
  //   permissions: ["admin", "customer"],
  //   navLink: "/pages/slider"
  // },
  // {
  //   id: "profile",
  //   title: "Profile",
  //   type: "item",
  //   icon: <Icon.User size={20} />,
  //   permissions: ["admin", "customer"],
  //   navLink: "/pages/profile",
  //   collapsed: true
  // },
  // {
  //   id: "accountSettings",
  //   title: "Account Settings",
  //   type: "item",
  //   icon: <Icon.Settings size={20} />,
  //   permissions: ["admin", "customer"],
  //   navLink: "/pages/account-settings"
  // },
  // {
  //   id: "faq",
  //   title: "FAQ",
  //   type: "item",
  //   icon: <Icon.HelpCircle size={20} />,
  //   permissions: ["admin", "customer"],
  //   navLink: "/pages/faq"
  // },

  // {
  //   id: "knowledgeBase",
  //   title: "Knowledge Base",
  //   type: "item",
  //   icon: <Icon.Info size={20} />,
  //   permissions: ["admin", "customer"],
  //   navLink: "/pages/knowledge-base",
  //   parentOf: [
  //     "/pages/knowledge-base/category/questions",
  //     "/pages/knowledge-base/category"
  //   ]
  // },
  // {
  //   id: "search",
  //   title: "Search",
  //   type: "item",
  //   icon: <Icon.Search size={20} />,
  //   permissions: ["admin", "customer"],
  //   navLink: "/pages/search"
  // },

  // {
  //   id: "invoice",
  //   title: "Invoice",
  //   type: "item",
  //   icon: <Icon.File size={20} />,
  //   permissions: ["admin", "customer"],
  //   navLink: "/pages/invoice"
  // },

  // {
  //   id: "authentication",
  //   title: "Authentication",
  //   type: "collapse",
  //   icon: <Icon.Unlock size={20} />,
  //   children: [
  //     {
  //       id: "login",
  //       title: "Login",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/pages/login",
  //       newTab: true
  //     },
  //     {
  //       id: "register",
  //       title: "Register",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/pages/register",
  //       newTab: true
  //     },
  //     {
  //       id: "forgotPassword",
  //       title: "Forgot Password",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/pages/forgot-password",
  //       newTab: true
  //     },
  //     {
  //       id: "resetPassword",
  //       title: "Reset Password",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/pages/reset-password",
  //       newTab: true
  //     },
  //     {
  //       id: "lockScreen",
  //       title: "Lock Screen",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/pages/lock-screen",
  //       newTab: true
  //     }
  //   ]
  // },
  // {
  //   id: "miscellaneous",
  //   title: "Miscellaneous",
  //   type: "collapse",
  //   icon: <Icon.FileText size={20} />,
  //   children: [
  //     {
  //       id: "comingSoon",
  //       title: "Coming Soon",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/misc/coming-soon",

  //       newTab: true
  //     },
  //     {
  //       id: "error",
  //       title: "Error",
  //       type: "collapse",
  //       icon: <Icon.Circle size={12} />,
  //       children: [
  //         {
  //           id: "404",
  //           title: "404",
  //           type: "item",

  //           icon: <Icon.Circle size={12} />,
  //           permissions: ["admin", "customer"],
  //           navLink: "/misc/error/404",

  //           newTab: true
  //         },
  //         {
  //           id: "500",
  //           title: "500",
  //           type: "item",

  //           icon: <Icon.Circle size={12} />,
  //           permissions: ["admin", "customer"],
  //           navLink: "/misc/error/500",

  //           newTab: true
  //         }
  //       ]
  //     },
  //     {
  //       id: "notAuthorized",
  //       title: "Not Authorized",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/misc/not-authorized",

  //       newTab: true
  //     },
  //     {
  //       id: "maintenance",
  //       title: "Maintenance",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/misc/maintenance",

  //       newTab: true
  //     }
  //   ]
  // },
  // {
  //   type: "groupHeader",
  //   groupTitle: "CHARTS & MAPS"
  // },
  // {
  //   id: "charts",
  //   title: "Charts",
  //   type: "collapse",
  //   badge: "success",
  //   badgeText: "3",
  //   icon: <Icon.PieChart size={20} />,
  //   children: [
  //     {
  //       id: "apex",
  //       title: "Apex",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/charts/apex"
  //     },
  //     {
  //       id: "chartJs",
  //       title: "ChartJS",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/charts/chartjs"
  //     },
  //     {
  //       id: "recharts",
  //       title: "Recharts",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "customer"],
  //       navLink: "/charts/recharts"
  //     }
  //   ]
  // },
  // {
  //   id: "leafletMaps",
  //   title: "Leaflet Maps",
  //   icon: <Icon.Map size={20} />,
  //   type: "item",
  //   permissions: ["admin", "customer"],
  //   navLink: "/maps/leaflet"
  // },
  // {
  //   type: "groupHeader",
  //   groupTitle: "EXTENSIONS"
  // },
  // {
  //   id: "sweetAlert",
  //   title: "Sweet Alerts",
  //   icon: <Icon.AlertCircle size={20} />,
  //   type: "item",
  //   permissions: ["admin", "customer"],
  //   navLink: "/extensions/sweet-alert"
  // },
  // {
  //   id: "toastr",
  //   title: "Toastr",
  //   icon: <Icon.Zap size={20} />,
  //   type: "item",
  //   permissions: ["admin", "customer"],
  //   navLink: "/extensions/toastr"
  // },
  // {
  //   id: "rcSlider",
  //   title: "Rc Slider",
  //   icon: <Icon.Sliders size={20} />,
  //   type: "item",
  //   permissions: ["admin", "customer"],
  //   navLink: "/extensions/slider"
  // },
  // {
  //   id: "fileUploader",
  //   title: "File Uploader",
  //   icon: <Icon.UploadCloud size={20} />,
  //   type: "item",
  //   permissions: ["admin", "customer"],
  //   navLink: "/extensions/file-uploader"
  // },
  // {
  //   id: "wysiwygEditor",
  //   title: "Wysiwyg Editor",
  //   icon: <Icon.Edit size={20} />,
  //   type: "item",
  //   permissions: ["admin", "customer"],
  //   navLink: "/extensions/wysiwyg-editor"
  // },
  // {
  //   id: "drag_&_drop",
  //   title: "Drag & Drop",
  //   icon: <Icon.Droplet size={20} />,
  //   type: "item",
  //   permissions: ["admin", "customer"],
  //   navLink: "/extensions/drag-and-drop"
  // },
  // {
  //   id: "tour",
  //   title: "Tour",
  //   icon: <Icon.Info size={20} />,
  //   type: "item",
  //   permissions: ["admin", "customer"],
  //   navLink: "/extensions/tour"
  // },
  // {
  //   id: "clipBoard",
  //   title: "Clipboard",
  //   icon: <Icon.Copy size={20} />,
  //   type: "item",
  //   permissions: ["admin", "customer"],
  //   navLink: "/extensions/clipboard"
  // },
  // {
  //   id: "contentMenu",
  //   title: "Context Menu",
  //   icon: <Icon.Menu size={20} />,
  //   type: "item",
  //   permissions: ["admin", "customer"],
  //   navLink: "/extensions/context-menu"
  // },
  // {
  //   id: "swiper",
  //   title: "Swiper",
  //   icon: <Icon.Smartphone size={20} />,
  //   type: "item",
  //   permissions: ["admin", "customer"],
  //   navLink: "/extensions/swiper"
  // },
  // {
  //   id: "access-control",
  //   title: "Access Control",
  //   icon: <Icon.Lock size={20} />,
  //   type: "item",
  //   permissions: ["admin", "customer"],
  //   navLink: "/extensions/access-control"
  // },
  // {
  //   id: "i18n",
  //   title: "I18n",
  //   icon: <Icon.Globe size={20} />,
  //   type: "item",
  //   permissions: ["admin", "customer"],
  //   navLink: "/extensions/i18n"
  // },
  // {
  //   id: "treeView",
  //   title: "Tree",
  //   icon: <Icon.GitPullRequest size={20} />,
  //   type: "item",
  //   permissions: ["admin", "customer"],
  //   navLink: "/extensions/tree"
  // },
  // {
  //   id: "extPagination",
  //   title: "Pagination",
  //   icon: <Icon.MoreHorizontal size={20} />,
  //   type: "item",
  //   permissions: ["admin", "customer"],
  //   navLink: "/extensions/pagination"
  // },
  // {
  //   id: "extImport",
  //   title: "Import",
  //   icon: <Icon.DownloadCloud size={20} />,
  //   type: "item",
  //   permissions: ["admin", "customer"],
  //   navLink: "/extensions/import"
  // },
  // {
  //   id: "extExport",
  //   title: "Export",
  //   icon: <Icon.UploadCloud size={20} />,
  //   type: "item",
  //   permissions: ["admin", "customer"],
  //   navLink: "/extensions/export"
  // },
  // {
  //   id: "extExportSelected",
  //   title: "Export Selected",
  //   icon: <Icon.CheckSquare size={20} />,
  //   type: "item",
  //   navLink: "/extensions/export-selected",
  //   permissions: ["admin", "customer"]
  // },
  // {
  //   type: "groupHeader",
  //   groupTitle: "OTHERS"
  // },
  // {
  //   id: "menuLevels",
  //   title: "Menu Levels",
  //   icon: <Icon.Menu size={20} />,
  //   type: "collapse",
  //   children: [
  //     {
  //       id: "secondLevel",
  //       title: "Second Level",
  //       icon: <Icon.Circle size={12} />,
  //       type: "item",
  //       permissions: ["admin", "customer"],
  //       navlink: ""
  //     },
  //     {
  //       id: "secondLevel1",
  //       title: "Second Level",
  //       icon: <Icon.Circle size={12} />,
  //       type: "collapse",

  //       children: [
  //         {
  //           id: "ThirdLevel",
  //           title: "Third Level",
  //           icon: <Icon.Circle size={12} />,
  //           type: "item",
  //           permissions: ["admin", "customer"],
  //           navLink: ""
  //         },
  //         {
  //           id: "ThirdLevel1",
  //           title: "Third Level",
  //           icon: <Icon.Circle size={12} />,
  //           type: "item",
  //           permissions: ["admin", "customer"],
  //           navLink: ""
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   id: "disabledMenu",
  //   title: "Disabled Menu",
  //   icon: <Icon.EyeOff size={20} />,
  //   type: "item",
  //   permissions: ["admin", "customer"],
  //   navLink: "#",
  //   disabled: true
  // },
  // {
  //   type: "groupHeader",
  //   groupTitle: "SUPPORT"
  // },
  // {
  //   id: "documentation",
  //   title: "Documentation",
  //   icon: <Icon.Folder size={20} />,
  //   type: "external-link",
  //   permissions: ["admin", "customer"],
  //   navLink:
  //     "https://pixinvent.com/demo/vuexy-react-admin-dashboard-template/documentation"
  // },
  // {
  //   id: "raiseSupport",
  //   title: "Raise Support",
  //   icon: <Icon.LifeBuoy size={20} />,
  //   type: "external-link",
  //   newTab: true,
  //   permissions: ["admin", "customer"],
  //   navLink: "https://pixinvent.ticksy.com/"
  // }
];

export default navigationConfig;
