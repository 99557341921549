import { history } from "../../../history"
import { config } from "../../../authServices/firebase/firebaseConfig"
import API from './../../helpers/Services/API';
import * as firebase from "firebase/app"
import "firebase/auth"
import "firebase/database"
const controller = `/admin/auth`;

// Init firebase if not already initialized
if (!firebase.apps.length) {
  firebase.initializeApp(config)
}

let firebaseAuth = firebase.auth()

// const initAuth0 = new auth0.WebAuth(configAuth)

export const getAllUsers = params => {
  return async dispatch => {
    await API.get(`${controller}/GetUsers`).then(response => {
      if (response.data.statusCode === 200) {
        dispatch({
          type: "GET_USERS",
          payload: response.data.data
        })
      }
      else {
        return {};
      }
    })
  }
}

export const loginWithJWT = user => {
  return dispatch => {
    dispatch({
      type: "LOGIN_WITH_JWT",
      payload: user
    })
    if (user.userRole === "admin" || user.userRole === "super admin") {
      history.push("/pages/AllAdmin?perPage=10&page=1")
    } else {
      history.push("/pages/AllDecks?page=1&perPage=10")
    }
    // let payload = { "loggedInUser": { "id": 1, "email": "administrator", "name": "Demo", "loggedInWith": "jwt" }, "loggedInWith": "jwt", "userRole": "customer" };
    // localStorage.setItem('userObj', JSON.stringify(payload))
    // dispatch({
    //     type: "LOGIN_WITH_JWT",
    //     payload: payload
    // })
    // history.push("/pages/AllUsers")
  }
}

export const alreadyLoggedIn = user => {
  // // debugger
  return dispatch => {
    let payload = JSON.parse(user);
    localStorage.setItem('userObj', JSON.stringify(payload))
    dispatch({
      type: "LOGIN_WITH_JWT",
      payload: payload
    })
    // payload.userRole === "customer" ? history.push("/pages/AllProducts") : history.push("/pages/AllUsers")

  }
}

export const logoutWithJWT = () => {
  return dispatch => {
    dispatch({ type: "LOGOUT_WITH_JWT", payload: {} })
    localStorage.removeItem('userObj')
    history.push("/pages/login")
  }
}

export const submitLoginWithFireBase = (email, password, remember) => {
  return dispatch => {
    let userEmail = null,
      loggedIn = false
    firebaseAuth
      .signInWithEmailAndPassword(email, password)
      .then(result => {
        firebaseAuth.onAuthStateChanged(user => {
          result.user.updateProfile({
            displayName: "Admin"
          })
          let name = result.user.displayName
          if (user) {
            userEmail = user.email
            loggedIn = true
            dispatch({
              type: "LOGIN_WITH_EMAIL",
              payload: {
                email: userEmail,
                name,
                isSignedIn: loggedIn,
                loggedInWith: "firebase"
              }
            })
          }
          if (user && remember) {
            firebase
              .auth()
              .setPersistence(firebase.auth.Auth.Persistence.SESSION)
              .then(() => {
                dispatch({
                  type: "LOGIN_WITH_EMAIL",
                  payload: {
                    email: userEmail,
                    name,
                    isSignedIn: loggedIn,
                    remember: true,
                    loggedInWith: "firebase"
                  }
                })
              })
          }
          history.push("/")
        })
      })
      .catch(error => {
        console.log(error)
      })
  }
}

export const loginWithFB = () => {
  return dispatch => {
    let provider = new firebase.auth.FacebookAuthProvider()
    provider.setCustomParameters({
      display: "popup"
    })
    firebaseAuth
      .signInWithPopup(provider)
      .then(result => {
        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        let token = result.credential.accessToken,
          // The signed-in user info.
          user = result.user.email
        dispatch({
          type: "LOGIN_WITH_FB",
          payload: {
            user,
            token,
            loggedInWith: "firebase"
          }
        })
        if (user) history.push("/")
      })
      .catch(error => {
        console.log(error)
      })
  }
}

export const loginWithTwitter = () => {
  return dispatch => {
    let provider = new firebase.auth.TwitterAuthProvider()
    firebaseAuth
      .signInWithPopup(provider)
      .then(function (result) {
        let token = result.credential.accessToken,
          user = result.user.email,
          name = result.user.displayName,
          photoUrl = result.user.photoURL
        dispatch({
          type: "LOGIN_WITH_TWITTER",
          payload: {
            user,
            name,
            photoUrl,
            token,
            loggedInWith: "firebase"
          }
        })
        history.push("/")
      })
      .catch(function (error) {
        console.log(error)
      })
  }
}

export const loginWithGoogle = () => {
  return dispatch => {
    let provider = new firebase.auth.GoogleAuthProvider()
    firebaseAuth
      .signInWithPopup(provider)
      .then(function (result) {
        let token = result.credential.accessToken,
          user = result.user.email,
          name = result.user.displayName,
          photoUrl = result.user.photoURL
        dispatch({
          type: "LOGIN_WITH_GOOGLE",
          payload: {
            email: user,
            name: name,
            photoUrl,
            token,
            loggedInWith: "firebase"
          }
        })
        history.push("/")
      })
      .catch(function (error) {
        console.log(error)
      })
  }
}

export const loginWithGithub = () => {
  return dispatch => {
    let provider = new firebase.auth.GithubAuthProvider()
    firebaseAuth
      .signInWithPopup(provider)
      .then(function (result) {
        let token = result.credential.accessToken,
          user = result.user.email,
          name = result.additionalUserInfo.username,
          photoUrl = result.user.photoURL

        dispatch({
          type: "LOGIN_WITH_GITHUB",
          payload: {
            user,
            name,
            photoUrl,
            token,
            loggedInWith: "firebase"
          }
        })
        history.push("/")
      })
      .catch(function (error) {
        console.log(error)
      })
  }
}

// export const loginWithJWT = user => {
//   // debugger
//   return dispatch => {
//     axios
//       .post("/api/authenticate/login/user", {
//         email: user.email,
//         password: user.password
//       })
//       .then(response => {
//         var loggedInUser

//         if (response.data) {
//           loggedInUser = response.data.user;
//           let payload= { loggedInUser, loggedInWith: "jwt", userRole:'editor' };
//           localStorage.setItem('userObj',JSON.stringify(payload))
//           dispatch({
//             type: "LOGIN_WITH_JWT",
//             payload: payload
//           })

//           history.push("/")
//         }
//       })
//       .catch(err => console.log(err))
//   }
// }



export const logoutWithFirebase = user => {
  return dispatch => {
    dispatch({ type: "LOGOUT_WITH_FIREBASE", payload: {} })
    history.push("/pages/login")
  }
}

export const changeRole = role => {
  return dispatch => dispatch({ type: "CHANGE_ROLE", userRole: role })
}
