import { combineReducers } from "redux"
import calenderReducer from "./calendar/"
import emailReducer from "./email/"
import chatReducer from "./chat/"
import todoReducer from "./todo/"
import customizer from "./customizer/"
import auth from "./auth/"
import navbar from "./navbar/Index"
import dataList from "./data-list/"
import CustomerReducer from "./customer"
import SalesReducer from "./sales"
import setup from "./setup"
import transaction from "./transaction"
import reports from "./reports"
import dropdown from "./dropdown"

const rootReducer = combineReducers({
  calendar: calenderReducer,
  emailApp: emailReducer,
  todoApp: todoReducer,
  chatApp: chatReducer,
  customer: CustomerReducer,
  sales: SalesReducer,
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  dataList: dataList,
  setup: setup,
  transaction: transaction,
  reports: reports,
  dropdown: dropdown,
})

export default rootReducer
