const initialState = {
  salesOrder: [],
  salesInvoice: {},
  salesProfit: {}
}

const ReportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_SALES_ORDER":
      if (action.payload.length > 0) {
        action.payload.isData = true;
      }
      else {
        action.payload.isData = false;
      }
      return {
        ...state,
        salesOrder: action.payload,
      }
    case "GET_SALE_INVOICE":
      return {
        ...state,
        salesInvoice: action.payload,
      }
    case "GET_SALE_PROFIT":
      return {
        ...state,
        salesProfit: action.payload,
      }
    default:
      return state
  }
}

export default ReportsReducer
