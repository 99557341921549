const initialState = {
    category: [],
    tag: [],
    reference: [],
    corporateBrand: [],
    deck: [],
    badge: [],
    user:[]
}

const DropdownReducer = (state = initialState, action) => {

    switch (action.type) {

        case "GET_ALL_CATEGORY":
            return {
                ...state,
                category: action.payload,
            }
        case "GET_ALL_TAG":
            return {
                ...state,
                tag: action.payload,
            }
        case "GET_ALL_REFERENCE":
            return {
                ...state,
                reference: action.payload,
            }
        case "GET_ALL_CORPORATE_BRAND":
            return {
                ...state,
                corporateBrand: action.payload,
            }
        case "GET_ALL_DECKS":
            return {
                ...state,
                deck: action.payload,
            }
        case "GET_ALL_BADGES":
            return {
                ...state,
                badge: action.payload,
            }
        case "GET_ALL_USERS":
            return {
                ...state,
                user: action.payload,
            }
        default:
            return state
    }
}

export default DropdownReducer
