const initialState = {
  ledgers: [],
  stocks: [],
  vouchers: []
}

const TransactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_AVAILABLE_STOCK":
      return {
        ...state,
        stocks: action.payload,
      }
    case "GET_LEDGER":
      if (action.payload.length > 0) {
        action.payload.isData = true;
      }
      else {
        action.payload.isData = false;
      }
      return {
        ...state,
        ledgers: action.payload,
      }
    case "GET_PRODUCTS":
      if (action.payload.length > 0) {
        action.payload.isData = true;
      }
      else {
        action.payload.isData = false;
      }
      return {
        ...state,
        products: action.payload,
      }
    case "GET_RECEIPT_VOUCHERS":
      // // debugger
      if (action.payload.length > 0) {
        action.payload.isData = true;
      }
      else {
        action.payload.isData = false;
      }
      return {
        ...state,
        vouchers: action.payload,
      }
    default:
      return state
  }
}

export default TransactionReducer
