const initialState = {
  businessUnit: [],
  branches: [],
  categories: [],
  customers: [],
  products: [],
  suppliers: [],
}

const SetupReducer = (state = initialState, action) => {

  switch (action.type) {

    case "GET_BUSINESS_UNIT":
      return {
        ...state,
        businessUnit: action.payload,
      }
    case "GET_BRANCHES":
      return {
        ...state,
        branches: action.payload,
      }
    case "GET_CATEGORIES":
      return {
        ...state,
        categories: action.payload,
      }
    case "GET_CUSTOMERS":
      return {
        ...state,
        customers: action.payload,
      }
    case "GET_PRODUCTS":
      if (action.payload.length > 0) {
        action.payload.isData = true;
      }
      else {
        action.payload.isData = false;
      }
      return {
        ...state,
        products: action.payload,
      }
    case "GET_SUPPLIERS":
      if (action.payload.length > 0) {
        action.payload.isData = true;
      }
      else {
        action.payload.isData = false;
      }
      return {
        ...state,
        suppliers: action.payload,
      }
    default:
      return state
  }
}

export default SetupReducer
