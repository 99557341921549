// import { combineReducers } from "redux"
// import { login } from "./loginReducer"
// import { register } from "./registerReducers"

// const authReducers = combineReducers({
//   login,
//   register
// })

// export default authReducers

const initialState = {
  userRole: "",
  users: []
}

const authReducers = (state = initialState, action) => {
  // // debugger
  switch (action.type) {
    case "GET_USERS": {
      return { ...state, users: action.payload }
    }
    case "LOGIN_WITH_EMAIL": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_FB": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_TWITTER": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_GOOGLE": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_GITHUB": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_JWT": {
      return { ...state, values: action.payload, userRole: action.payload.userRole }
    }
    case "LOGOUT_WITH_JWT": {
      return { ...state, values: action.payload }
    }
    case "LOGOUT_WITH_FIREBASE": {
      return { ...state, values: action.payload }
    }
    case "CHANGE_ROLE": {
      return { ...state, userRole: action.userRole }
    }
    case "SIGNUP_WITH_EMAIL": {
      return { ...state, values: action.payload }
    }
    case "SIGNUP_WITH_JWT":
      return {
        ...state,
        values: action.payload
      }
    default: {
      return state
    }
  }
}
export default authReducers